var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"title":"动态"}},[_c('article',{attrs:{"id":"page-newList"}},[_c('Collection',{attrs:{"name":("classify/" + _vm.id)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var model = ref.model;
return [_c('section',{staticClass:"container"},[_c('div',{staticClass:"breadcrumb"},[_c('ol',{staticClass:"r m-auto"},[_c('li',[_c('router-link',{staticClass:"home",attrs:{"to":"/"}},[_c('a-icon',{attrs:{"type":"environment"}}),_vm._v("首页")],1)],1),_c('li',[_c('router-link',{attrs:{"to":("/article/list/" + (model.post_id))}},[_vm._v(_vm._s(model.post_title))])],1),_c('li',[_c('a',[_vm._v("正文")])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-20 col-left hidden-xs"},[_c('div',{attrs:{"id":"type"}},[_c('h2',[_c('i',{staticClass:"iconfont iconxinwendongtai"}),_vm._v("动态")]),(model.parent)?_c('dl',[_c('Collection',{attrs:{"name":"classify","query":{ parent: model.parent }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
return _vm._l((collection),function(item){return _c('dd',{key:item.post_id,class:{ active: model.post_id == item.post_id }},[_c('div',[_c('router-link',{attrs:{"to":("/article/list/" + (item.post_id))}},[_vm._v(" "+_vm._s(item.post_title)),_c('a-icon',{attrs:{"type":"right-square"}})],1)],1)])})}}],null,true)})],1):_vm._e()])]),_c('div',{staticClass:"col-xs-20 col-right"},[_c('Collection',{attrs:{"name":"post","limit":_vm.provider.defaultPageSize,"page":_vm.provider.current,"query":{ target: _vm.id }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var collection = ref.collection;
var count = ref.count;
return [_c('div',{staticClass:"tab"},[_c('ul',{staticClass:"tab-heade"},[_c('li',{staticClass:"active"},[_c('a',{attrs:{"id":"lmmc"}},[_vm._v(_vm._s(model.post_title))])])]),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane active mb-5 overflow-hidden",attrs:{"id":"news_list"}},[_c('ul',_vm._l((collection),function(info){return _c('li',{key:info.post_id},[_c('p',[_c('router-link',{attrs:{"to":("/article/detail/" + (info.post_id)),"title":info.post_title}},[_vm._v(" "+_vm._s(info.post_title)+" ")])],1),_c('span',[_vm._v(_vm._s(_vm.moment(info.created).format('YYYY-MM-DD')))])])}),0)]),_c('div',{staticClass:"pagetion",attrs:{"id":"page"}},[_c('a-pagination',{attrs:{"show-quick-jumper":"","current":_vm.provider.current,"defaultPageSize":_vm.provider.defaultPageSize,"total":count},on:{"change":_vm.onChange}})],1)])])]}}],null,true)})],1)])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }