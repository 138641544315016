<template>
<Page title="动态">
<article id="page-newList">
  <Collection  :name="`classify/${id}`"   v-slot="{ model }">
    <section class="container">
    <div class="breadcrumb">
      <ol class="r m-auto">
        <li><router-link to="/" class="home"><a-icon type="environment" />首页</router-link></li>
        <li>
          <router-link :to="`/article/list/${model.post_id}`">{{model.post_title}}</router-link>
        </li>
        <li><a>正文</a></li>
      </ol>
    </div>
    <div class="row">
      <div class="col-xs-20 col-left hidden-xs">
        <div id="type">
          <h2><i class="iconfont iconxinwendongtai"></i>动态</h2>
          <dl v-if="model.parent">
             <Collection
                  name="classify"
      v-slot="{ collection }"
      :query="{ parent: model.parent }">
            <dd  :class="{ active: model.post_id == item.post_id }"   v-for="item in collection" :key="item.post_id">
              <div>
              <router-link :to="`/article/list/${item.post_id}`">
                {{item.post_title}}<a-icon type="right-square" />
                </router-link>
                </div>
            </dd>
             </Collection>
          </dl>
        </div>
      </div>
      <div class="col-xs-20 col-right">
        <Collection
          name="post"
          :limit="provider.defaultPageSize"
          :page="provider.current"
          v-slot="{ collection, count }"
          :query="{ target: id }">
        <div class="tab">
          <ul class="tab-heade">
            <li class="active"><a id="lmmc">{{model.post_title}}</a></li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane active mb-5 overflow-hidden" id="news_list">
              <ul>
                <li v-for="info in collection" :key="info.post_id">
                  <p><router-link  :to="`/article/detail/${info.post_id}`" :title="info.post_title">
                    {{info.post_title}}
                      </router-link>
                      </p>
                  <span>{{ moment(info.created).format('YYYY-MM-DD') }}</span>
                  </li>
              </ul>
            </div>
  <div class="pagetion" id="page">
<a-pagination
                  show-quick-jumper
                  :current="provider.current"
                  :defaultPageSize="provider.defaultPageSize"
                  :total="count"
                  @change="onChange"
                />
  </div>

          </div>
        </div>
        </Collection>
      </div>
    </div>
  </section>
  </Collection>
</article>
</Page>
</template>
<script>
import Collection from '@/components/Collection'
export default {
  name: 'ArticleList',
  components: {
    Collection
  },
  data () {
    return {
      id: this.$route.params.id,
      provider: {
        current: 1,
        defaultPageSize: 12,
        limit: 1
      }
    }
  },
  methods: {
    onChange (page, pageSize) {
      this.provider.current = this.provider.limit = page
    }
  },
  activated () {
    this.id = this.$route.params.id
  },
  watch: {
    '$route.params.id': function (id) {
      this.id = id
    }
  }
}
</script>
<style scoped src="../../assets/css/list.css"></style>
